<script setup>
    import { onMounted, reactive, ref } from 'vue'

    import MZBreadcrumb from '@/components-chillpainai/MZBreadcrumb.vue'

    import { useReserveStore } from "@/store/reserve"
    import { useRoute } from 'vue-router'
    import { useToast } from "primevue/usetoast"

    const reserveStore = useReserveStore()
    const route = useRoute()
    const toast = useToast()

    const model = reactive({})
    const initial = ref(false)
    const loading = ref(false)

    const update = () => {
        loading.value = true
        
        reserveStore.update(route.params.id, { type: model.type }).then(({ data }) => {
            if (data.error) {
                toast.add({severity:'warn', summary: 'Warning', detail: data.error.message, life: 3000})
                return
            }
                
            toast.add({severity:'success', summary: 'Success', detail: 'Update Successful.', life: 3000})
            loading.value = false
        }).catch(e => {
            toast.add({severity:'error', summary: 'Error (Catch)', detail: e.message, life: 3000})
        })
    }

    onMounted(() => {
        if (route.params.id) {
            reserveStore.show(route.params.id).then(({ data }) => {
                Object.assign(model, data.reserve)

                initial.value = true
            })
        }
    })
</script>

<template>
    <MZBreadcrumb :items="[{ label: 'Reserve' }, { label: 'Edit' }]" />
    <ProgressBar v-if="! initial" mode="indeterminate" style="height: 3px" />

    <br/>

    <div v-if="initial" class="flex flex-wrap justify-content-center card-container">
        <!-- ข้อมูลการจอง -->
        <div class="col-12 lg:col-6">
            <div class="card" style="min-height: 420px;">
                <div class="card-body">
                    <div class="col-12">
                        <div class="p-fluid">
                            <h5 class="card-title">ข้อมูลการจอง</h5>
                            <h6 class="card-subtitle mb-2 text-muted"></h6>
                            <p class="card-text"></p>
                            
                            <div class="field">
                                <label for="uid">UID</label>
                                <InputText v-model="model.uid" id="uid" type="text" disabled />
                            </div>
                            <div class="field">
                                <label for="type">สถานะ</label>
                                <Dropdown v-model="model.type" :options="['new', 'confirmed', 'canceled']" />
                            </div>
                            <div class="field">
                                <label for="customer_name">ชื่อ</label>
                                <InputText v-model="model.customer_name" id="customer_name" type="text" disabled />
                            </div>
                            <div class="field">
                                <label for="customer_phone">เบอร์โทร</label>
                                <InputText v-model="model.customer_phone" id="customer_phone" type="text" disabled />
                            </div>
                            <div class="field">
                                <label for="customer_email">อีเมลล์</label>
                                <InputText v-model="model.customer_email" id="customer_email" type="text" disabled />
                            </div>
                            <div class="field">
                                <label for="customer_notes">โน๊ต</label>
                                <div class="card">
                                    <div class="card-body">
                                        <div class="col-12">
                                            <div style="white-space: pre-wrap;">{{ model.customer_notes }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="field">
                                <label for="data">Data</label>
                                <div class="card">
                                    <div class="card-body">
                                        <div class="col-12">
                                            <ul>
                                                <li v-for="(value, key) in model.data" :key="key">
                                                    {{ key }}: {{ JSON.stringify(value) }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <br/>
                        </div>

                        <div class="text-right">
                            <Button
                                :disabled="loading"
                                label="บันทึก" 
                                :loading="loading"
                                @click="update"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- ข้อมูลทัวร์ -->
        <div v-if="model.tour" class="col-12 lg:col-6">
            <div class="card" style="min-height: 420px;">
                <div class="card-body">
                    <div class="col-12">
                        <div class="p-fluid">
                            <h5 class="card-title">ข้อมูลทัวร์</h5>
                            <h6 class="card-subtitle mb-2 text-muted"></h6>
                            <p class="card-text"></p>
                            
                            <div class="field">
                                <label>Tour ID</label>
                                <br/>
                                <router-link :to="{name: 'tours.edit', params: {id: model.tour.id}}">
                                    <Button :label="`# ${model.tour.id}`" class="p-button-text" />
                                </router-link>
                            </div>
                            <div class="field">
                                <label>Title</label>
                                <InputText :value="model.tour.title" disabled />
                            </div>
                            <div class="field">
                                <label>Location</label>
                                <InputText :value="model.tour.location" disabled />
                            </div>

                            <br/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
